@import 'variables.css';

input {
  background-color: var(--neutral-color900);
  border-radius: var(--border-radius);
  border: var(--border-thin) solid var(--neutral-color500);
  color: var(--font-color);
  flex: 1;
  font-size: 1em;
  padding: var(--s-2);
  width: 100%;
}

input:focus {
  border: var(--border-thin) solid var(--neutral-color500);
}

.site-search__result_container {
  background-color: var(--search-result-box-background);
  backdrop-filter: blur(6px);
  border: var(--border-thin) solid var(--neutral-color400);
  display: none;
  position: absolute;
  max-height: var(--search-result-box-max-height);
  overflow-y: scroll;
  overflow-x: hidden;
  right: var(--s3);
  top: var(--topnav-height);
  width: var(--search-result-box-width);
  z-index: 1;
}

.site-search__result_container.visible {
  display: block;
}

.site-search__category_filter_container {
  background-color: var(--topnav-background-color);
  border-bottom: var(--border-thin) solid var(--neutral-color600);
  display: flex;
  justify-content: space-between;
  padding: var(--s-1);
  position: sticky;
  top: 0;
}

.site-search__category_filter_label {
  border-radius: var(--border-radius);
  font-size: var(--font-size-caption);
  font-weight: var(--font-weight-medium);
  padding: 0 var(--s-2);
}

.site-search__category_filter_label:hover {
  color: var(--primary-color500);
  cursor: pointer;
}

.category-filter_label-active {
  background-color: var(--search-result-label-background);
  color: var(--primary-color500);
  cursor: pointer;
}

.site-search__result {
  border: var(--border-default) solid transparent;
  cursor: pointer;
  padding: var(--s-4) 0;
}

.site-search__result:hover,
.site-search__result-active {
  background-color: var(--search-result-box-background-active);
  border-left: var(--border-default) solid var(--primary-color500);
  color: var(--primary-color500);
}

.search-result__tag {
  background-color: var(--search-result-label-background);
  border-radius: var(--border-radius);
  float: right;
  font-size: var(--font-size-info);
  font-weight: var(--font-weight-medium);
  margin: 0 var(--s-1);
  padding: 0 var(--s-2);
}

.site-search__result:hover > .search-result__tag {
  background-color: var(--search-result-label-background-active);
}

.search-result__title {
  margin: var(--s-5) 0;
  padding: 0 var(--s-1);
}

.search-result__description {
  color: var(--neutral-color300);
  font-size: var(--font-size-code);
  margin: var(--s-5) 0;
  overflow: hidden;
  padding: 0 var(--s-1);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-result__none {
  color: var(--neutral-color300);
  margin-top: var(--s-4);
  padding-bottom: var(--s-2);
  text-align: center;
}

.search-result__showing_for,
.search-result__search_for {
  text-align: left;
  color: var(--neutral-color300);
  padding-left: var(--s-1);
  padding-bottom: 0;
  font-size: var(--font-size-code);
}

.search-result__showing_for {
  padding-top: var(--s-2);
}

.search-result__search_for {
  padding-bottom: var(--s-2);
}

.search-result__showing_for > .search-result__showing_for_query,
.search-result__search_for > .search-result__search_for_query {
  color: var(--primary-color500);
}

.search-result__showing_for > .search-result__showing_for_query {
  font-weight: 800;
}

.search-result__showing_for > .search-result__showing_for_query:hover,
.search-result__search_for > .search-result__search_for_query:hover {
  color: var(--primary-color700);
  cursor: pointer;
  text-decoration: underline;
}

.search-result__highlighted_text {
  background-color: var(--search-result-text-highlight);
  border-radius: var(--border-radius);
}

.site-search__result:hover .search-result__highlighted_text {
  background-color: var(--search-result-text-highlight-active);
  color: var(--neutral-color050);
}
