@import 'variables.css';

.layout__sidenav {
  display: flex;
}

.layout__sidenav > :first-child {
  height: calc(100vh - var(--topnav-height));
  position: fixed;
  width: var(--sidenav-width);
  overflow-y: scroll;
}
.layout__sidenav > * + * {
  margin-left: var(--sidenav-width);
}

.sidenav {
  display: flex;
  flex-direction: column;
  background-color: var(--sidenav-background-color);
  color: var(--sidenav-color);
  padding: var(--topnav-horizontal-padding);
  padding-top: var(--sidebar-layout-padding-top);
  overflow-y: auto;
}
.sidenav > * {
  font-size: var(--font-size-body);
  line-height: var(--line-height-caption);
  user-select: none;
}
.sidenav ul {
  margin: 0;
  padding: 0;
}

/*
  Set the spacing between sections of links.

  # Friday project idea
  It would be nice to have a more semantic nesting of links,
  rather than <li>s and <ul>s mixed together, so that's a Friday project
*/
.sidenav--nested > ul > ul + li,
.sidenav--nested > ul > li + li,
.sidenav--nested > ul > ul + ul {
  margin-top: var(--s1);
}

/*.sidenav > * > li > ul,*/
.sidenav--nested > * > li > ul,
.sidenav ul > ul {
  margin: 0;
  padding-left: var(--s0);
}

.sidenav li {
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidenav li a,
.sidenav li a:visited,
.sidenav input {
  color: var(--sidenav-color);
  outline: none;
}

/* Styles for the collapse functionality */
.sidenav--parent i.fas.fa-chevron-up {
  float: right;
}

.sidenav--parent.collapsed i.fas.fa-chevron-up {
  transform: rotate(180deg);
}

.sidenav--child,
.sidenav--parent,
i.fas.fa-chevron-up,
.sidenav--child > * {
  transition: all 0.5s ease;
}

.sidenav--child > * {
  max-height: 28px;
}

/* Override if child is also a parent */
.sidenav--child-parent > * {
  max-height: unset;
}

.sidenav--child.collapsed > * {
  max-height: 0px;
  transform: rotateX(90deg);
  opacity: 0;
}

/* styles for search items */
.sidenav .search-wrapper {
  background: var(--sidenav-background-color);
  position: sticky;
  top: calc(-1 * var(--sidebar-layout-padding-top) - 1px);
  padding-top: var(--s-5);
  z-index: 2;
}

.sidenav .search-box {
  box-shadow: var(--box-shadow-1);
  margin-bottom: 1rem;
  margin-top: var(--s-5);
  position: relative;
}

.sidenav li.hidden {
  display: none;
}

.sidenav .search--cancel {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 0.8rem);
  right: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid;
  background-color: var(--neutral-color800);
  color: var(--neutral-color500);
  border-radius: 50%;
}

@media (max-width: 720px) {
  .sidenav .search--cancel {
    display: none;
  }
}
