@import 'variables.css';

.styles__color_blocks {
  display: flex;
  flex-direction: column;
}

.styles__color_block_container {
  display: flex;
  align-items: center;
}

.styles__color_block_container > * + * {
  margin-left: var(--s-3);
}

.color_block {
  height: var(--s2);
  width: var(--s3);
  border-radius: var(--s-3);
}
.color_block--primary050 {
  background-color: var(--primary-color050);
}
.color_block--primary100 {
  background-color: var(--primary-color100);
}
.color_block--primary200 {
  background-color: var(--primary-color200);
}
.color_block--primary300 {
  background-color: var(--primary-color300);
}
.color_block--primary400 {
  background-color: var(--primary-color400);
}
.color_block--primary500 {
  background-color: var(--primary-color500);
}
.color_block--primary600 {
  background-color: var(--primary-color600);
}
.color_block--primary700 {
  background-color: var(--primary-color700);
}
.color_block--primary800 {
  background-color: var(--primary-color800);
}
.color_block--primary900 {
  background-color: var(--primary-color900);
}

.color_block--neutral050 {
  background-color: var(--neutral-color050);
}
.color_block--neutral100 {
  background-color: var(--neutral-color100);
}
.color_block--neutral200 {
  background-color: var(--neutral-color200);
}
.color_block--neutral300 {
  background-color: var(--neutral-color300);
}
.color_block--neutral400 {
  background-color: var(--neutral-color400);
}
.color_block--neutral500 {
  background-color: var(--neutral-color500);
}
.color_block--neutral600 {
  background-color: var(--neutral-color600);
}
.color_block--neutral700 {
  background-color: var(--neutral-color700);
}
.color_block--neutral800 {
  background-color: var(--neutral-color800);
}
.color_block--neutral900 {
  background-color: var(--neutral-color900);
}

.color_block--supporting050 {
  background-color: var(--supporting-color050);
}
.color_block--supporting100 {
  background-color: var(--supporting-color100);
}
.color_block--supporting200 {
  background-color: var(--supporting-color200);
}
.color_block--supporting300 {
  background-color: var(--supporting-color300);
}
.color_block--supporting400 {
  background-color: var(--supporting-color400);
}
.color_block--supporting500 {
  background-color: var(--supporting-color500);
}
.color_block--supporting600 {
  background-color: var(--supporting-color600);
}
.color_block--supporting700 {
  background-color: var(--supporting-color700);
}
.color_block--supporting800 {
  background-color: var(--supporting-color800);
}
.color_block--supporting900 {
  background-color: var(--supporting-color900);
}

.color_block--accent050 {
  background-color: var(--accent-color050);
}
.color_block--accent100 {
  background-color: var(--accent-color100);
}
.color_block--accent200 {
  background-color: var(--accent-color200);
}
.color_block--accent300 {
  background-color: var(--accent-color300);
}
.color_block--accent400 {
  background-color: var(--accent-color400);
}
.color_block--accent500 {
  background-color: var(--accent-color500);
}
.color_block--accent600 {
  background-color: var(--accent-color600);
}
.color_block--accent700 {
  background-color: var(--accent-color700);
}
.color_block--accent800 {
  background-color: var(--accent-color800);
}
.color_block--accent900 {
  background-color: var(--accent-color900);
}

.color_block--error050 {
  background-color: var(--error-color050);
}
.color_block--error100 {
  background-color: var(--error-color100);
}
.color_block--error200 {
  background-color: var(--error-color200);
}
.color_block--error300 {
  background-color: var(--error-color300);
}
.color_block--error400 {
  background-color: var(--error-color400);
}
.color_block--error500 {
  background-color: var(--error-color500);
}
.color_block--error600 {
  background-color: var(--error-color600);
}
.color_block--error700 {
  background-color: var(--error-color700);
}
.color_block--error800 {
  background-color: var(--error-color800);
}
.color_block--error900 {
  background-color: var(--error-color900);
}
