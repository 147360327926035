@import url('https://fonts.googleapis.com/css?family=Raleway:200,400,500,700&display=swap');

:root {
  /*
    Colors

    Ditch hex for HSL
    Hex and RGB are the most common formats for representing color on the web, but they’re not the most useful.
    Using hex or RGB, colors that have a lot in common visually look nothing alike in code.
    HSL fixes this by representing colors using attributes the human-eye intuitively perceives: hue, saturation, and lightness.

    Where a color is defined in the brand guidelines, it's referenced it in a comment.
   */
  --primary-color050: hsl(14, 90%, 96%);
  --primary-color100: hsl(8, 98%, 80%);
  --primary-color200: hsl(6, 87%, 77%);
  --primary-color300: hsl(4, 85%, 74%); /* 'even paler RG red' */
  --primary-color400: hsl(4, 83%, 69%); /* 'pale RG red' */
  --primary-color500: hsl(1, 84%, 65%); /* 'RG red' */
  --primary-color600: hsl(0, 80%, 61%);
  --primary-color700: hsl(0, 70%, 55%);
  --primary-color800: hsl(358, 66%, 50%);
  --primary-color900: hsl(358, 60%, 40%);

  --neutral-color050: hsl(216, 33%, 97%);
  --neutral-color100: hsl(214, 15%, 91%);
  --neutral-color200: hsl(210, 16%, 82%);
  --neutral-color300: hsl(211, 13%, 65%);
  --neutral-color400: hsl(211, 10%, 53%);
  --neutral-color500: hsl(211, 12%, 43%);
  --neutral-color600: hsl(209, 14%, 37%);
  --neutral-color700: hsl(209, 15%, 28%);
  --neutral-color800: hsl(210, 18%, 22%); /* Charcoal */
  --neutral-color850: hsl(210, 17%, 20%);
  --neutral-color900: hsl(207, 16%, 17%);

  --supporting-color050: hsl(22, 100%, 95%);
  --supporting-color100: hsl(22, 100%, 86%);
  --supporting-color200: hsl(22, 92%, 76%);
  --supporting-color300: hsl(21, 83%, 64%);
  --supporting-color400: hsl(22, 78%, 55%);
  --supporting-color500: hsl(22, 71%, 45%);
  --supporting-color600: hsl(22, 74%, 38%);
  --supporting-color700: hsl(22, 79%, 31%);
  --supporting-color800: hsl(22, 80%, 26%);
  --supporting-color900: hsl(22, 83%, 19%);

  --accent-color050: hsl(163, 70%, 96%);
  --accent-color100: hsl(163, 75%, 87%);
  --accent-color200: hsl(162, 73%, 74%);
  --accent-color300: hsl(164, 70%, 63%);
  --accent-color400: hsl(166, 64%, 49%);
  --accent-color500: hsl(168, 78%, 41%);
  --accent-color600: hsl(170, 91%, 32%);
  --accent-color700: hsl(172, 94%, 26%);
  --accent-color800: hsl(172, 98%, 20%);
  --accent-color900: hsl(176, 100%, 13%);

  --error-color050: hsl(45, 100%, 96%);
  --error-color100: hsl(45, 90%, 88%);
  --error-color200: hsl(45, 86%, 81%);
  --error-color300: hsl(43, 90%, 76%);
  --error-color400: hsl(43, 89%, 70%);
  --error-color500: hsl(42, 78%, 60%);
  --error-color600: hsl(42, 63%, 48%);
  --error-color700: hsl(43, 72%, 37%);
  --error-color800: hsl(43, 77%, 27%);
  --error-color900: hsl(43, 86%, 17%);

  --kronograph-orange: hsla(24, 100%, 61%, 1);

  --brand-linear-gradient: 153deg, var(--primary-color600) 0, var(--primary-color500) 42%,
    var(--supporting-color400) 75%, var(--supporting-color300) 100%;
  --unauthenticated-background-color: hsl(0, 50%, 53%);

  /*
    This is actually hard-coded in the patterns.css file.
    TODO: interpolate this value in the the background-image prop, used by the bg patters.
   */
  --unauthenticated-palette-color: hsl(0, 41%, 49%);

  /*
    Spacing system
  */
  --ratio: 1.5;
  --s0: 1rem;
  --s-1: calc(var(--s0) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));

  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
  --s7: calc(var(--s6) * var(--ratio));
  --s8: calc(var(--s7) * var(--ratio));

  --border-radius: var(--s-4);
  --border-thin: var(--s-5);
  --border-thick: var(--s-1);
  --border-default: var(--s-4);

  /*
    Font size
    approximately based on font size used by a selection of sites, including NY Times, Wired, and Medium.
  */
  font-size: calc(0.222vw + 1em);
  line-height: var(--ratio);

  --font-color: var(--neutral-color100);

  --font-size-code: 0.8rem;
  --font-size-tag: 0.9rem;
  --font-size-caption: 0.85rem;
  --font-size-code-inline: 0.9rem;
  --font-size-body: 1rem;
  --font-size-info: 0.7rem;
  --font-size-body-large: 1.1rem;
  --font-size-h6: 1.15rem;
  --font-size-h5: 1.22rem;
  --font-size-h4: 1.3rem;
  --font-size-h3: 1.6rem;
  --font-size-h2: 1.9rem;
  --font-size-h1: 2.35rem;

  --font-color-h6: var(--neutral-color200);
  --font-color-h5: var(--neutral-color200);
  --font-color-h4: var(--neutral-color100);
  --font-color-h3: var(--neutral-color100);

  --font-weight-light: 100;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-heavy: 700;

  --font-weight-caption: var(--font-weight-regular);
  --font-weight-body: var(--font-weight-regular);
  --font-weight-body-large: var(--font-weight-regular);
  --font-weight-h6: var(--font-weight-medium);
  --font-weight-h5: var(--font-weight-medium);
  --font-weight-h4: var(--font-weight-regular);
  --font-weight-h3: var(--font-weight-light);
  --font-weight-h2: var(--font-weight-light);
  --font-weight-h1: var(--font-weight-light);

  --line-height-caption: 150%;
  --line-height-body: 1;
  --line-height-body-large: 1;
  --line-height-h6: 1;
  --line-height-h5: 1;
  --line-height-h4: 1;
  --line-height-h3: 1;
  --line-height-h2: 1.1;
  --line-height-h1: 1;

  --letter-spacing-caption: 0;
  --letter-spacing-body: 0;
  --letter-spacing-body-large: 0;
  --letter-spacing-h6: 0;
  --letter-spacing-h5: 0;
  --letter-spacing-h4: 0;
  --letter-spacing-h3: 0;
  --letter-spacing-h2: 0;
  --letter-spacing-h1: 0;

  --font-family-heading: 'Raleway', Arial, sans-serif;
  --font-family-body: 'Muli', 'Helvetica Neue', Helvetica, sans-serif;
  --font-family-monospace: 'PT Mono', monospace;

  --box-shadow-0: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --box-shadow-1: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --box-shadow-2: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --box-shadow-3: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --box-shadow-4: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --box-shadow-inset: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

  --background-color: var(--neutral-color800);
  --color-light: var(--neutral-color050);
  --color-dark: var(--neutral-color900);
  --measure: 70ch;

  --topnav-background-color: var(--neutral-color900);
  --topnav-horizontal-padding: var(--s0);
  --topnav-color: var(--neutral-color100);
  --topnav-height: 6ex;
  --topnav-logo-height: 52px;
  --topnav-logo-image-width: 19px;
  --toggle-line-height: 3px;
  --toggle-active-translate: 4px;

  --sidenav-background-color: var(--neutral-color850);
  --sidenav-color: var(--neutral-color300);
  --sidenav-width: 28ch;
  --sidebar-layout-padding-top: var(--topnav-height);

  --link-hover-gradient: 153deg, var(--primary-color600) 0, var(--primary-color500) 42%,
    var(--primary-color400) 75%, var(--supporting-color400) 100%;

  --max-content-with-sidebar-width: calc(100vw - var(--sidenav-width));

  --welcome-screen-max-content-width: 70rem;

  /*
    Workaround a CSS/postprocesser issue - you have to set the units here.
   */
  --content-side-padding: 0px;

  --api-indentation: var(--s1);

  /*
    I'm using actual pixels here as a work around
    for a bug in the chart-example panel that means it won't
    handle the vertical resize correctly when the height is based
    off of our dynamic font size.
   */
  --chart-example-min-height: 250px;
  --chart-example-max-height: 350px;

  /*
    Site Search Variables
  */
  --search-result-box-background: hsla(207, 16%, 17%, 0.8); /*  --neutral-color900 */
  --search-result-box-background-active: hsla(211, 10%, 53%, 0.5); /* --neutral-color400 */
  --search-result-box-max-height: 34em;
  --search-result-box-width: 33em;
  --search-result-text-highlight: hsla(358, 66%, 50%, 0.3); /* --primary-color-800 */
  --search-result-text-highlight-active: hsla(0, 80%, 61%, 0.8); /* --primary-color-600 */
  --search-result-label-background: hsl(209, 15%, 28%, 0.4); /* --neutral-color-700 */
  --search-result-label-background-active: hsl(211, 10%, 53%, 0.4); /* --neutral-color-400 */
}

/*
 This is the width at which the content gets too squished when a side nav bar is present.
 I don't like having a fixed pixel value but it's unavoidable with the fixed position side bar.
 Unfortunately, we can't use CSS Grid over the fixed side bar because Internet Explorer.
*/
@media (max-width: 977px) {
  :root {
    --sidenav-width: 25vw;
    --content-side-padding: 0.5rem;
  }
}
