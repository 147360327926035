@import 'variables.css';

body {
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-regular);
  margin: 0;
}

body > * + * {
  margin-top: var(--topnav-height);
}
