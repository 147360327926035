@import 'variables.css';

.main-storybook {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - var(--topnav-height));
  width: 100vw;
  background-color: var(--neutral-color100);
}
