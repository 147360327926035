@import 'variables.css';

.chart {
  width: 99.9%;
  height: 100%;
  background-color: var(--neutral-color700);
}

.docs {
  width: var(--measure);
  max-width: calc(var(--max-content-with-sidebar-width) - var(--s0));
}

.chart-example {
  min-height: var(--chart-example-min-height);
  z-index: 0;
}

.chart-example > * {
  flex: 0 0 50%;
}

/* Code container block for doc examples */
.chart-example .code {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin: 0;
}

.chart-example .loading-placeholder,
.chart-example .deferred-error {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-h3);
  color: var(--neutral-color600);
}

.chart-wrapper {
  border: none;
  z-index: 0;
}

.code-wrapper {
  position: relative;
  overflow: auto;
}

.chart-wrapper,
.code-wrapper {
  display: inline-block;
  height: var(--chart-example-max-height);
  width: 50%;
  vertical-align: top;
}

.chart-example .playground-link {
  right: var(--s-1);
  position: sticky;
  float: right;
  margin-top: calc(-1 * var(--s3));
}

.dependencies__item {
  padding: var(--s-3) 0;
}

.dependencies__license {
  padding-left: var(--s0);
}

.switcher > .docs__text-then-image > * {
  flex-basis: calc(0.4 * var(--measure));
  margin: 0;
}

.switcher > .docs__text-then-image > .text {
  flex-basis: calc(0.6 * var(--measure));
}

.docs__text-then-image > .text > * {
  margin: 0;
  /*
    We can't use the stack class in the HTML, so set margins here instead
  */
}
.docs__text-then-image > .text > * + * {
  margin-top: var(--s0);
  /*
    We can't use the stack class in the HTML, so set margins here instead
  */
}

.switcher > .docs__text-then-image img {
  max-width: var(--s7);
}

.floating-chart {
  display: inline-block;
  position: sticky;
  width: 50%;
  height: var(--s8);
  vertical-align: top;
  top: var(--s3);
  background-color: transparent;
}

.floating-chart .caption {
  text-align: center;
  margin: 0 var(--s1);
}

.floating-chart ul {
  font-size: var(--font-size-code);
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.floating-chart ul li {
  margin: 0 var(--s0) var(--s-4) var(--s0);
  white-space: nowrap;
  flex: 1;
}

.floating-chart ul li.break {
  flex-basis: 100%;
  height: 0;
}

.managed-content {
  color: var(--neutral-color400);
}

.managed-content h3 {
  color: var(--neutral-color400);
}

.managed-content .active,
.managed-content .active h3 {
  color: var(--font-color);
}

.table-events {
  border-collapse: collapse;
  font-size: 0.9rem;
}

.table-events th {
  background: var(--neutral-color900);
  color: var(--neutral-color200);
  text-align: left;
  border: none;
  height: 40px;
  padding: calc(var(--s-1) / var(--ratio));
}

.table-events td {
  border: none;
  height: 40px;
  padding: calc(var(--s-1) / var(--ratio));
}

.table-events tr:nth-child(odd) td {
  background: var(--neutral-color700);
}
.table-events tr:nth-child(even) td {
  background: var(--neutral-color800);
}

.table-events.dependencies {
  width: 100%;
}

a[href^='http']:after,
a[href^='https']:after {
  font-family: 'Font Awesome 5 Free', sans-serif; /* requires a generic font family as a fallback, otherwise test fails with 'Unexpected missing generic font family' */
  content: '\f35d';
  padding-left: 3px;
}

.instructions__link[href^='http']:after {
  content: normal;
}

.docs-text-collapsable.hidden {
  display: none;
}

.docs-text-collapsable {
  display: block;
}

.docs-text-collapse-button {
  cursor: pointer;
  padding: 4px;
  width: 100%;
  height: fit-content;
  background-color: var(--neutral-color700);
  border-radius: var(--border-radius);
  text-align: center;
}

.docs-text-collapse-button i.fas {
  transform: rotate(180deg);
  display: inline-flex;
  -webkit-text-fill-color: initial;
  transition: all 0.5s ease;
}

.docs-text-collapse-button.spin i.fas {
  transform: rotate(0deg);
}

/*
 CSS for toggleable tabs above tutorial code snippets
*/
.tabset > input[type='radio'] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset .tab-panel pre.code {
  margin: 0;
  border-top-left-radius: 0;
}

.tabset > input:nth-of-type(1):checked ~ .tab-panels > .tab-panel:nth-of-type(1),
.tabset > input:nth-of-type(2):checked ~ .tab-panels > .tab-panel:nth-of-type(2),
.tabset > input:nth-of-type(3):checked ~ .tab-panels > .tab-panel:nth-of-type(3) {
  display: block;
}

.tabset > label {
  position: relative;
  display: inline-block;
  padding: var(--s-3) var(--s-1);
  cursor: pointer;
  font-weight: var(--font-weight-heavy);
  border-width: 1px 1px 0 1px;
  border-color: var(--neutral-color600);
  border-style: solid;
  border-top-left-radius: var(--s-4);
  border-top-right-radius: var(--s-4);
  background-color: var(--neutral-color800);
}

.tabset > label:hover {
  background-color: var(--neutral-color600);
}

.tabset > input:focus + label,
.tabset > input:checked + label {
  background-color: var(--neutral-color850) !important;
}

.tabset > input:checked + label::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: var(--primary-color700);
}

.tabset > input:focus-visible + label {
  outline: 2px solid currentcolor;
  outline: 2px solid -webkit-focus-ring-color;
}
