@import 'variables.css';

.download__version_title_container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.download__version_title_container__title {
  margin-bottom: var(--s0);
  margin-top: 0;
}

.download__version_title_container__date {
  color: var(--neutral-color300);
}

.text-collapsable.text-collapsable--collapsed {
  display: none;
}

.text-collapse-button::after {
  content: ' …';
}

.text-collapsable {
  display: block;
}

.text-collapse-button {
  cursor: pointer;
  border-bottom: 1px dashed var(--neutral-color600);
  width: fit-content;
}

.download__version_title_container + img,
.download__version_title_container + video {
  margin-top: 0;
}

.download__video {
  max-width: 100%;
}
