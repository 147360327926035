@import 'variables.css';

.api ul {
  padding: 0;
}
.api ul ul {
  margin-left: var(--api-indentation);
}
.api ul > .api-row {
  margin-top: var(--s0);
}

.api .name,
.api .data-type {
  align-self: flex-start;
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-code-inline);
  font-weight: var(--font-weight-medium);
}

.name-separator {
  margin: 0px 4px 0px 2px;
}

.api span.default > .label {
  font-family: var(--font-family-body);
}

.api-row {
  list-style: none;
}

.api-inline-type .interface {
  margin-bottom: var(--s-2);
}

.api-signature > .api-parameters > .api-row:last-child,
.interface > ul > .api-row {
  margin-bottom: var(--s1);
}

.api-row li {
  margin-bottom: var(--s-4);
}

.api-header .default {
  display: block;
  font-weight: normal;
  color: var(--neutral-color300);
}

.api-header .default .value {
  font-weight: bold;
  margin-left: 1ch;
}
.api-header,
.api-desc,
.api-example,
.api__docify-inject,
.api-desc + ul,
.api-desc + p {
  border-left: var(--border-thin) solid var(--neutral-color600);
  padding: 0;
  padding-top: var(--s-5);
  padding-bottom: var(--s-5);
  padding-left: var(--api-indentation);
}

/*
  Used at Chart props > view
 */
.api__docify-inject ul {
  padding-left: var(--s1);
}

.api__docify-inject__image-fit-description {
  margin-left: var(--api-indentation);
}

.api__docify-inject__image-fit-description > * + * {
  margin-top: var(--s-4);
}

.api__docify-inject__image-fit-description__row {
  display: flex;
}

.api-row .api__docify-inject__image-fit-description__row > .data-type {
  margin-left: 0;
}
.api__docify-inject__image-fit-description__row > * + * {
  margin-left: var(--api-indentation);
}

.api-row > * + .api-header {
  /*
  we can't use the .stack class in this page, due to the structure of how the DOM is generated,
  so implementing it here instead.
   */
  margin-top: var(--s2);
}

.api-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.api-header__left,
.api-header__right {
  display: flex;
}

.api-signature__heading > .api-header__right {
  max-width: 77px;
}

.api-desc {
  margin: 0;
}

.api-return-desc {
  margin-left: var(--api-indentation);
}

.api-row .data-type {
  display: inline-block;
  font-weight: normal;
  margin-left: var(--s0);
}

.api-signature {
  margin-top: var(--s-1);
  margin-bottom: var(--s1);
}

ul.api__indented-list {
  /*
    <ul>s on the api page are generally used for props.
    In the few cases where we use an <ul> for something else,
    this class is to ensure it has correct padding.
   */
  padding-left: 1rem;
}

.api-signature__heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  /*
    With the current API, we know that api signatures
    will only be nested one level deep, so we can get
    away with setting the margin in here.
   */
  margin-left: var(--api-indentation);
}

.api-signature__heading__title {
  font-size: var(--font-size-h6);
}

/*
   When docify generates the 'Chart mode' and 'map mode' headings,
   they look like a prop, which they are not, they are just headings.
   So we have to undo the styling on them with the  following CSS.

   Issue: https://gitlab.com/cambridgeintelligence/rg/issues/747
 */

#chart-view-Chart\ Mode > *,
#chart-params-onChartChange-view-Chart\ Mode > *,
#chart-view-Map\ Mode > *,
#chart-params-onChartChange-view-Map\ Mode > *,
#chart-view-Legacy\ view\ API > * {
  border: none;
}

#chart-view-Chart\ Mode:first-child > .api-header .name,
#chart-params-onChartChange-view-Chart\ Mode:first-child > .api-header .name,
#chart-params-onViewChange-Chart\ Mode:first-child > .api-header .name,
#chart-view-Map\ Mode > div.api-header span.name,
#chart-params-onChartChange-view-Map\ Mode > div.api-header span.name,
#chart-params-onViewChange-Map\ Mode > .api-header .name,
#chart-view-Legacy\ view\ API > div.api-header span.name {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-h6);
  background-color: inherit;
  color: inherit;
  font-weight: inherit;
  border-radius: 0;
  padding: 0;
}

.link-icon .copy-alert,
.link-icon .copy-alert:hover,
.link-icon:hover .copy-text {
  animation: fadeIn ease 0.3s;
  color: var(--neutral-color100);
  margin-left: 10px;
  width: max-content;
  display: inline;
  background-color: var(--primary-color500);
  position: absolute;
  font-size: var(--font-size-caption);
  padding: 0 var(--s-4);
  border-radius: 2px;
  transition: all;
}

.link-icon .copy-text {
  display: none;
}
