@import 'variables.css';

pre.code,
pre > code:only-child {
  background-color: var(--neutral-color850);
  border-radius: var(--border-radius);

  color: var(--neutral-color100);
  font-size: var(--font-size-code);
  overflow: auto;
  padding: var(--s-1) var(--s0);
  border: 1px solid var(--neutral-color600);
}

pre.code.code--chart-example {
  overflow: visible;
  border: none;
}

/* Inline code symbol/name */
code,
a.code,
.api-header .name {
  color: var(--neutral-color800);
  background-color: var(--neutral-color300);
  padding: 2px var(--s-4);
  border-radius: 2px;
  font-weight: var(--font-weight-heavy);
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-code-inline);
}

a.code {
  text-decoration: underline;
}

a.code:hover {
  background-color: var(--neutral-color200);
}

/* only child code blocks are formed from inline code */
pre > code:only-child {
  white-space: pre;
  display: block;
}
