@import 'variables.css';

.footer {
  font-size: 0.7rem;
  min-height: var(--s2);
  align-items: flex-start;

  .center:first-child {
    padding: 0px var(--s3);
  }
}

#demos > footer > .footer {
  background-color: transparent;
}
.footer__content {
  display: flex;
  max-width: var(--welcome-screen-max-content-width);
  flex-direction: column;
  gap: var(--s0);
  margin: 0px;
  padding: var(--s2) var(--s3);
}

.footer hr {
  color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: var(--welcome-screen-max-content-width);
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--s1);

  /* Support button */
  > .button {
    min-width: 0;
    background-color: transparent;
    border: 1px solid var(--accent-color600);
    flex: 0 0 auto;
    font-size: 0.8rem;
    padding: var(--s-1) var(--s-1);
    margin: 0px;
    &:hover {
      background-color: var(--accent-color600);
    }
  }

  > div {
    margin: 0px;
    > .footer__copy:nth-child(1) {
      padding-right: var(--s-1);
      font-weight: var(--font-weight-heavy);
    }
  }
}
.footer__logo {
  flex-basis: var(--s4);
  max-width: var(--s5);
}

.linkedin__logo {
  flex-basis: var(--s-2);
  max-width: var(--s0);
}

.footer__content > * {
  flex: 1;
}

.footer__row > * {
  flex: 1;
  margin: 0 var(--topnav-horizontal-padding);
}

.footer__copy,
.footer__logo {
  padding: 0;
  align-self: flex-end;
  margin: 0px;
  display: inline-block;
}

* {
  box-sizing: border-box;
}

pre.code {
  overflow-wrap: normal;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

body > .unauthenticated {
  margin-top: 0;
}

img {
  width: 100%;
}
.img--xsmall {
  max-width: var(--s5);
}
.img--small {
  max-width: var(--s7);
}
.img--medium {
  max-width: var(--s8);
}

.copy {
  max-width: var(--measure);
  text-align: left;
}

input.checkbox {
  /*
    TODO: make a nicely styled checkbox
   */
  flex: unset;
  width: var(--s0);
}

.box {
  background-color: var(--background-color);
  border: var(--border-thin) solid;
  border-radius: var(--border-radius);
  color: var(--color-light);
  padding: var(--s0) var(--s1);
}

.box * {
  color: inherit;
}

.box--invert {
  background-color: var(--color-light);
  color: var(--color-dark);
}

.center {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}

.cluster {
  --space: var(--s0);
  /* ↓ Suppress horizontal scrolling caused by the negative margin in some circumstances */
  overflow: hidden;
}

.cluster > * {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* ↓ multiply by -1 to negate the halved value */
  margin: calc(var(--space) / 2 * -1);
}

.cluster > * > * {
  /* ↓ half the value, because of the 'doubling up' */
  margin: calc(var(--space) / 2);
}

.cluster--large {
  --space: var(--s3);
}

.cover {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1rem;
}

.cover > * {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.cover > :last-child:not(.cover__head-element) {
  margin-bottom: 0;
}

.cover > :first-child:not(.cover__principle-element) {
  margin-top: 0;
}

.cover > .cover__principle-element {
  margin-bottom: auto;
  margin-top: auto;
}

.frame {
  --n: 9;
  --d: 16;
  padding-bottom: calc(var(--n) / var(--d) * 100%);
  position: relative;
}
.frame > * {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.frame > img,
.frame > video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack > * {
  margin-bottom: 0;
  margin-top: 0;
}

.stack > * + * {
  margin-top: var(--s0);
}

.stack--small > * + * {
  margin-top: var(--s-1);
}

.stack--large > * + * {
  margin-top: var(--s2);
}

.stack--xlarge > * + * {
  margin-top: var(--s4);
}

/** 
* TODO may be we should think of a different way of styling css
* we could think of classes like .mt-s-0, .mt-s1 ...
*/
.stack--large > .stack:nth-child(2),
.stack--large > .stack > .stack:nth-child(2) {
  margin-top: var(--s0);
}

.stack--xlarge div.stack div.stack,
.stack--large div.stack div.stack {
  margin-top: var(--s2);
}

.stack--large > p,
.stack--large > ul {
  margin-top: var(--s0);
}

.stack--split {
  display: inline-block;
  width: 50%;
}

.chart-example {
  margin-top: var(--s0);
}

.with-sidebar {
  overflow: hidden;
}

.with-sidebar > * {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--s1) / 2 * -1);
}

.with-sidebar > * > * {
  flex-grow: 1;
  margin: calc(var(--s1) / 2);
}

.with-sidebar > * > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(50% - var(--s1));
}

.switcher > * {
  border: none;
  display: flex;
  flex-wrap: wrap;
}
.switcher.switcher--reverse > * {
  flex-wrap: wrap-reverse;
}

.switcher > * > * {
  flex-grow: 1;
  flex-basis: calc((var(--measure) - (100% - var(--s1))) * 999);
  margin: calc(var(--s1) / 2);
}

.switcher > * > :nth-last-child(n + 5),
.switcher > * > :nth-last-child(n + 5) ~ * {
  /*
    Here, I am applying a flex-basis of 100% to each element,
    only where there are five or more elements in total.
    The nth-last-child(n+5) selector targets any elements that are
    more than 4 from the end of the set. Then, the general sibling combinator (~)
    applies the same rule to the rest of the elements
    (it matches anything after :nth-last-child(n+5)).
    If there are fewer that 5 items, no :nth-last-child(n+5)
    elements and the style is not applied.
    This is optional and may not be what we stick with.
   */
  flex-basis: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.caption {
  font-size: var(--font-size-caption);
  color: var(--neutral-color400);
}
a {
  --link-hover-gradient: 153deg, var(--primary-color600) 0, var(--primary-color500) 42%,
    var(--primary-color400) 75%, var(--supporting-color400) 100%;
  --nav-link-hover-gradient: 153deg, var(--primary-color500) 0, var(--primary-color300) 42%,
    var(--primary-color200) 75%, var(--supporting-color100) 100%;
  --note-link-hover-gradient: 153deg, var(--supporting-color900) 0, var(--supporting-color800) 42%,
    var(--supporting-color700) 75%, var(--primary-color300) 100%;
  color: inherit;
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  border-bottom: 1px solid var(--neutral-color300);
}

.a--plain {
  color: inherit;
  text-decoration: none;
  border-bottom: none;
}

header a,
nav a {
  border-bottom: none;
}

a:hover > code,
a:not(.button):not(.a--plain):not(.code):not(a.badge-alpha):not(a.badge-beta):not(
    a.badge-deprecated
  ):hover {
  background: linear-gradient(var(--link-hover-gradient));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

header .nav ul a:hover:not(#account) {
  display: inline;
  color: white;
  background: linear-gradient(var(--nav-link-hover-gradient));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

a.badge-alpha,
a.badge-beta,
span.badge-alpha,
span.badge-beta {
  background: var(--error-color500);
  border-radius: var(--s-1);
  color: var(--error-color900);
  float: right;
  font-family: var(--font-family-body);
  font-size: 0.5rem;
  font-weight: var(--font-weight-heavy);
  letter-spacing: 0.01rem;
  padding: var(--s-5) var(--s-3);
  text-transform: uppercase;
}

a.badge-alpha:hover,
a.badge-beta:hover {
  color: var(--neutral-color050);
}

span.badge-preview {
  background: #62b0e8;
  border-radius: var(--s-1);
  color: var(--accent-color900);
  float: right;
  font-family: var(--font-family-body);
  font-size: 0.5rem;
  font-weight: var(--font-weight-heavy);
  letter-spacing: 0.01rem;
  padding: var(--s-5) var(--s-3);
  text-transform: uppercase;
}

a.badge-deprecated,
span.badge-deprecated {
  background: var(--neutral-color400);
  border-radius: var(--s-1);
  color: var(--neutral-color900);
  float: right;
  font-family: var(--font-family-body);
  font-size: 0.5rem;
  font-weight: var(--font-weight-heavy);
  letter-spacing: 0.01rem;
  padding: var(--s-5) var(--s-3);
  text-transform: uppercase;
}

a.badge-deprecated:hover {
  background: var(--neutral-color400);
  color: var(--neutral-color050);
}

header a.nav__logo__link:hover,
nav.sidenav a:hover,
a.badge-deprecated,
a.badge-deprecated:hover,
a.badge-alpha,
a.badge-alpha:hover,
a.badge-beta,
a.badge-beta:hover {
  background-image: unset !important;
  -webkit-background-clip: unset !important;
  background-clip: text;
  -webkit-text-fill-color: inherit !important;
  border-bottom: none !important;
}

a.badge-alpha.inline,
a.badge-alpha.inline:hover,
a.badge-beta.inline,
a.badge-beta.inline:hover,
a.badge-deprecated.inline,
a.badge-deprecated.inline:hover {
  float: unset;
  display: inline;
}

nav.sidenav {
  scroll-behavior: smooth;
}

.button:disabled {
  background-color: var(--neutral-color300);
  color: var(--neutral-color500);
}

a.button {
  text-decoration: none;
  border-bottom: none;
}

.button {
  --button-text-color: var(--primary-color050);
  background-color: var(--primary-color700);
  border-radius: var(--border-radius);
  color: var(--button-text-color);
  cursor: pointer;
  font-size: var(--font-size-body-large);
  font-weight: var(--font-weight-heavy);
  letter-spacing: 0.02em;
  padding: var(--s-2) var(--s0);
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow-0);
  border: none;
}
.button > img {
  margin-right: var(--s-2);
  width: 27px;
}
.button--accent {
  --button-text-color: var(--accent-color900);
  background-color: var(--accent-color500);
  color: var(--button-text-color);
}

.button--plain {
  background-color: inherit;
  box-shadow: none;
  color: inherit;
}

.button--neutral {
  background-color: var(--neutral-color200);
  color: var(--neutral-color900);
}

.button--github {
  background-color: #fff;
  --button-text-color: #111;
  color: var(--button-text-color);
}
.button--gitlab {
  background-color: #6653b5;
  color: white;
}

.button--google {
  background-color: #4285f4;
  color: white;
}
.button--google > img {
  background-color: #fff;
  border-radius: 2px;
}

.playground-link {
  position: absolute;
  right: calc(50% + var(--s0));
  bottom: var(--s0);
  width: var(--s2);
  height: var(--s2);
  padding: var(--s-2);
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--primary-color400);
  box-shadow: var(--box-shadow-1);
  opacity: 0.85;
}

.display--none {
  display: none;
}

.row {
  display: flex;
  align-items: center;
}

.error {
  background-color: var(--error-color400);
  color: var(--error-color900);
  border: var(--border-default) solid var(--error-color800);
  border-radius: var(--border-radius);
  padding: var(--s-1);
  max-width: var(--measure);
}
.error a {
  border-bottom: 1px solid var(--error-color800);
}

input {
  background-color: var(--neutral-color900);
  border-radius: var(--border-radius);
  border: var(--border-thin) solid var(--neutral-color500);
  color: var(--font-color);
  flex: 1;
  font-size: 1em;
  padding: var(--s-2);
  width: 100%;
}
input:focus {
  border: var(--border-thin) solid var(--neutral-color400);
}
::-webkit-input-placeholder {
  color: var(--neutral-color500);
}
:-ms-input-placeholder {
  color: var(--neutral-color500);
}
::placeholder {
  color: var(--neutral-color500);
}

.layout__main {
  width: 100%;
  max-width: 100%;
  min-height: calc(100vh - var(--topnav-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-color);
}

.layout__content {
  padding-bottom: var(--s1);
  padding-left: var(--content-side-padding);
  padding-right: var(--content-side-padding);
}

.layout__content .welcome {
  margin-bottom: calc(-1 * var(--s1));
  margin-left: calc(-1 * var(--content-side-padding));
  margin-right: calc(-1 * var(--content-side-padding));
}
.layout__content > .center {
  padding-top: var(--topnav-height);
}

.layout__content > .center > :first-child > :first-child {
  margin-top: 0;
}

.layout__sidenav .layout__main {
  max-width: var(--max-content-with-sidebar-width);
  width: var(--max-content-with-sidebar-width);
}

.content > :first-child > :first-child {
  margin-top: 0;
}
.content {
  padding-top: var(--sidebar-layout-padding-top);
}
.content--full-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.paper {
  padding: var(--s2);
  box-shadow: var(--box-shadow-1);
  background-color: var(--neutral-color050);
  border-radius: var(--border-radius);
}
.paper--bg100 {
  background-color: var(--neutral-color100);
}

:target::before {
  /*
    when a section is navigated to using a fragment id (i.e. docs/#combos)
    the title will be obscured by the fixed nav bar.
    This will make sure that the section is at the expected height
   */
  content: '';
  display: block;
  height: var(--topnav-height);
}

.sidenav .sidenav--nested a.highlight--active,
.sidenav .sidenav--nested a.highlight--active:hover {
  color: var(--primary-color600);
  background: linear-gradient(
    153deg,
    var(--primary-color600) 0,
    var(--primary-color500) 42%,
    var(--primary-color400) 75%,
    var(--supporting-color400) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

.link-icon:hover {
  color: var(--primary-color500);
}

.link-icon {
  padding-left: 10px;
  position: relative;
  font-size: var(--font-size-caption);
  cursor: pointer;
}
