@import 'variables.css';

@font-face {
  font-family: 'Muli';
  src: local('Muli-Regular.ttf'), url('../fonts/Muli/Muli-Regular.ttf');
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: light;
  src: local('Muli-Light.ttf'), url('../fonts/Muli/Muli-Light.ttf');
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: bold;
  src: local('Muli-Bold.ttf'), url('../fonts/Muli/Muli-Bold.ttf');
}

@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: normal;
  src: local('Muli-Italic.ttf'), url('../fonts/Muli/Muli-Italic.ttf');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-heading);
  color: var(--font-color);
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  letter-spacing: var(--letter-spacing-h1);
  line-height: var(--line-height-h1);
}
h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  letter-spacing: var(--letter-spacing-h2);
  line-height: var(--line-height-h2);
}
h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
  letter-spacing: var(--letter-spacing-h3);
  line-height: var(--line-height-h3);
  color: var(--font-color-h3);
}
h4 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  letter-spacing: var(--letter-spacing-h4);
  line-height: var(--line-height-h4);
  color: var(--font-color-h4);
}
h5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  letter-spacing: var(--letter-spacing-h5);
  line-height: var(--line-height-h5);
  color: var(--font-color-h5);
}
h6 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
  letter-spacing: var(--letter-spacing-h6);
  line-height: var(--line-height-h6);
  color: var(--font-color-h6);
}
