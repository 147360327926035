@import 'variables.css';

.welcome {
  display: flex;
  flex-direction: column;
}

.welcome__hero__title,
.showcase__section__title,
.showcase__title,
.showcase__text {
  color: white;
  font-family: 'Muli', 'Helvetica Neue', Helvetica, sans-serif;
}

.showcase__section__title {
  margin-top: 0px;
  margin-bottom: var(--s0);
}

.welcome__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.welcome__row--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.welcome__row > * {
  flex: 1 1 var(--s8);
}

/* Contains svg hero image */
.welcome__hero__right {
  position: absolute;
  top: -200px;
  right: -140px;
}

.welcome__hero__left {
  display: flex;
  flex-direction: column;
  gap: var(--s0);
}

.welcome__hero__title {
  line-height: 1.15;
  color: white;
  font-weight: light;

  > span {
    font-weight: var(--font-weight-heavy);
  }
}

.welcome__hero__title--em {
  font-style: italic;
}

.showcase__image__container {
  overflow: hidden;
  aspect-ratio: 16 / 10; /* Ensure images are all same size */
  border-radius: var(--s-2);
}

.showcase__image__container > img {
  display: block;
  height: 100%;
  object-fit: cover; /* Ensures the image is cropped and not stretched */
  transition: transform 0.4s ease;
}

.showcase__text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .inline__icon__button {
    span {
      text-decoration: underline;
    }
  }
  .showcase__text__copy:nth-child(2) {
    margin-bottom: var(--font-size-caption);
  }
}

.inline__icon__button {
  display: flex;
  gap: var(--s-1);
}

.icon {
  width: 1rem;
  height: auto;
}

.showcase__title {
  font-weight: var(--font-weight-heavy);
}

.a--plain[href^='http']:after {
  content: normal;
}

.welcome__section {
  padding: var(--s2) var(--s3);
}

.welcome__section--gradient {
  padding: var(--s4) var(--s3);
  color: var(--color-dark);
  overflow: hidden;
  background-image: url('/img/welcome-hero-gradient-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.welcome__section__content {
  max-width: var(--welcome-screen-max-content-width);
  position: relative;
}

/* CSS for KG banner */

.kg-banner {
  display: flex;
  position: relative;
  overflow: clip;
  width: 26rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem 1.8rem;
  border-radius: 5rem;
  cursor: pointer;
  transition: background-color 0.5s ease;
  z-index: 2;

  > * {
    flex: 0 0 50%;
  }

  &:hover {
    background-color: rgba(255, 136, 56, 0.5);
    /* Move arrow icon up and right */
    .icon {
      margin-top: -8px;
      margin-left: 8px;
    }
  }
}

.kg-banner__text {
  display: flex;
  flex-direction: column;
  gap: var(--s-3);

  > * {
    font-size: var(--font-size-caption);
  }
  .inline__icon__button {
    gap: var(--s-2);
    .icon {
      transition: margin 0.6s ease;
      width: 0.6rem;
    }
  }
}

.kg-banner__title,
.kg-banner__text {
  color: black;
  font-family: 'Muli', 'Helvetica Neue', Helvetica, sans-serif;
  margin: 0px;
}

.kg-banner__title {
  font-weight: var(--font-weight-heavy);
}

.kg-banner__image {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 50%;
}

.welcome__main__content {
  background: radial-gradient(
      112.33% 108.33% at 98.21% 87.68%,
      rgba(26, 184, 153, 0.1) 0%,
      rgba(239, 139, 85, 0) 100%
    ),
    radial-gradient(
      23.7% 84.53% at 57.08% 8.45%,
      rgba(239, 139, 85, 0.1) 0%,
      rgba(239, 139, 85, 0) 100%
    ),
    radial-gradient(
      70.6% 62.27% at 12.37% 70.98%,
      rgba(235, 76, 76, 0.1) 0%,
      rgba(239, 139, 85, 0) 100%
    ),
    #242c32;
}

#showcase__grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.2rem;
}

.showcase {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--s-1);
  padding: var(--font-size-caption);
  overflow: hidden;
  justify-content: space-between;

  > * {
    font-size: var(--font-size-caption);
    z-index: 2;
  }

  .inline__icon__button {
    margin-top: auto;
  }

  &:hover {
    > .showcase__image__container > img {
      transform: translate(-6%, -6%) scale(1.18);
    }
  }
}

.showcase,
.showcase__text {
  > * + * {
    font-size: var(--font-size-caption);

    margin-top: var(--font-size-caption);
  }
}

/* Showcase card hover effect styling */
.showcase::before,
.showcase::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.showcase::before {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.showcase:hover::before {
  transform: translate(-50%, -50%) scale(2);
  opacity: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%);
}

@media (max-width: 1000px) {
  .welcome__hero__right {
    display: none;
  }
}

@media (max-width: 1280px) {
  #showcase__grid {
    grid-template-columns: 1fr 1fr;
  }

  .welcome__hero__right {
    right: calc(-140px - 8vw);
  }
}

@media (max-width: 800px) {
  #showcase__grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 520px) {
  .welcome__hero__title {
    text-align: center;
  }

  .kg-banner {
    min-width: 0;
    width: 100%;
    justify-content: center;
    align-items: center;

    > * {
      flex: 0 0 auto;
    }
  }

  .kg-banner__image {
    display: none;
  }
}
