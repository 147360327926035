@import 'variables.css';

.nav {
  background-color: var(--topnav-background-color);
  box-sizing: border-box;
  color: var(--topnav-color);
  height: var(--topnav-height);
  position: fixed;
  top: 0;
  width: 100%;
  /* z-index needed so nav is on top of the RG demos */
  z-index: 500;
}

.nav * {
  user-select: none;
}

.nav input {
  user-select: text;
}

.horizontal-nav .nav {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vertical-nav .nav {
  display: flex;
  flex-direction: column;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.horizontal-nav .nav ul {
  display: flex;
  height: 30px;
}
.vertical-nav .nav ul {
  margin-left: auto;
  margin-right: auto;
}

.horizontal-nav .nav li {
  display: flex;
  align-items: center;
}

.horizontal-nav .nav ul > li + li {
  margin-left: var(--s2);
}
.horizontal-nav .nav ul > li + li:last-child {
  margin-left: var(--s0);
}
.vertical-nav .nav ul > li + li {
  margin-top: var(--s1);
}
.nav li a,
.nav li a:visited {
  color: var(--topnav-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  outline-style: none;
}

.nav li a:focus {
  outline: 1px dotted rgb(229, 232, 235);
  outline: 5px auto -webkit-focus-ring-color;
}

.horizontal-nav__top-container,
.vertical-nav__top-container {
  padding: 0 var(--topnav-horizontal-padding);
}
.vertical-nav__top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.horizontal-nav li a {
  text-align: center;
}
.vertical-nav li a {
  font-size: 1.2rem;
}
#vertical-nav-link-container {
  display: none;
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--sidenav-background-color);
}
#vertical-nav-link-container.active {
  display: inline-block;
}
#vertical-nav-links {
  width: fit-content;
}
#vertical-nav-links.active {
  display: inline-block;
}

#nav-toggle-button {
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  text-transform: capitalize;
  user-select: none;
}

#nav-toggle-button > * + * {
  margin-top: calc(2 * var(--toggle-line-height));
}

.bar1,
.bar2,
.bar3 {
  width: var(--s1);
  height: var(--toggle-line-height);
  background-color: var(--neutral-color050);
  transition: 0.2s;
}
/* Rotate first bar */
#nav-toggle-button.active .bar1 {
  transform: rotate(-45deg) translate(-4px, 4px);
}

/* Fade out the second bar */
#nav-toggle-button.active .bar2 {
  opacity: 0;
}

/* Rotate last bar */
#nav-toggle-button.active .bar3 {
  margin-top: 0;
  transform: rotate(45deg) translate(-4px, -4px);
}

.nav .avatar {
  border-radius: 50%;
  display: inline-block;
  height: var(--s1);
  width: var(--s1);
}
.vertical-nav .nav .avatar {
  width: var(--s3);
  height: var(--s3);
}

.nav__logo a {
  color: var(--neutral-color050);
  text-decoration: none;
  height: var(--topnav-logo-height);
  display: flex;
  align-items: center;
  /* line-height to get a pleasing similarity between the reversed RG 'R' in the img and the 'ReGraph' text. */
  line-height: var(--topnav-logo-height);
  font-size: 1em;
}

.nav__logo a > * + * {
  margin-left: var(--s-4);
}

.nav__logo__image {
  width: var(--topnav-logo-image-width);
}

/* Duplicate from sdk.css to keep styling for showcases*/

header a {
  --nav-link-hover-gradient: 153deg, var(--primary-color500) 0, var(--primary-color300) 42%,
    var(--primary-color200) 75%, var(--supporting-color100) 100%;
  --note-link-hover-gradient: 153deg, var(--supporting-color900) 0, var(--supporting-color800) 42%,
    var(--supporting-color700) 75%, var(--primary-color300) 100%;
  color: inherit;
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}

header a:hover > code,
header a:not(.button):not(.a--plain):hover,
/* These are custom as background-clip: text only affects a div's direct content
Safari also doesn't let child nodes inherit these styles so we use the :not selector
to target all children of the result entry. */
header a:hover :not(.result__header__page):not(code) {
  background: linear-gradient(var(--link-hover-gradient));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

header .horizontal-nav__top-container a.nav__item {
  display: inline;
}

/* Hide strangely formatted injected content.
This is always part of a large description that we don't want to show anyway. */
.api__docify-inject {
  display: none;
}

@media (max-width: 1170px) {
  .horizontal-nav {
    display: none;
  }
}

@media (min-width: 1171px) {
  .vertical-nav {
    display: none;
  }
}
